<template>
  <div style="display: flex; flex-direction: column">
    <div style="display: flex; justify-content: center">
      <img
        src="../assets/TPI_logo.png"
        alt="logo"
        style="
          width: 800px;
          height: 200px;
          margin-top: 40px;
          margin-bottom: 40px;
        "
      />
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <form @submit.prevent="onSubmit">
        <div v-if="registering" style="width: 400px; margin-left: 25px">
          <v-text-field
            color="secondary"
            v-model="name"
            label="Name"
          ></v-text-field>
        </div>

        <div style="padding: 25px">
          <v-text-field
            color="secondary"
            v-model="email"
            label="Email Address"
          ></v-text-field>
        </div>
        Sign in is done via verified email so no password is necessary

        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 25px;
          "
        >
          <v-btn style="width: 200px" type="submit" color="primary">{{
            registering ? "Register" : "Log In"
          }}</v-btn>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 25px;
            "
          >
            {{ signInActionText }}
            <div
              style="
                color: blue;
                width: 100px;
                display: inline;
                cursor: pointer;
              "
              @click="toggleRegisterView()"
            >
              &nbsp; Click here!
            </div>
          </div>
        </div>
      </form>
    </div>
    <div>
      <vModal
        :draggable="true"
        width="45%"
        height="35%"
        name="modal"
        @before-close="beforeClose"
        style="border-radius: 10px"
      >
        <div
          v-if="showModal"
          style="
            height: 100%;
            display: flex;
            flex-direction: column;
            text-algin: center;
            align-items: center;
            justify-content: space-around;
          "
        >
          <v-icon
            v-if="success"
            icon="check-circle"
            color="primary"
            style="width: 120px; height: 100px; font-size: 140px"
            >mdi-check-circle-outline</v-icon
          >
          <v-icon
            v-else
            color="warning"
            style="width: 120px; height: 100px; font-size: 140px"
            >mdi-alert-circle</v-icon
          >
          <b style="text-align: center" v-html="modalMessage"></b>
        </div>
      </vModal>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { mapMutations } from "vuex";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../main.js";
import { verifyUserExists } from "../../api/endpoints/user";

export default {
  data() {
    return {
      email: "",
      name: "",
      // firebase requires a password for register, but we will never use it. Setting to something secure just in case
      password: "444QT4Azm8ugWtnd6TTC",
      registering: false,
      modalMessage: "",
      showModal: false,
      success: false,
    };
  },
  computed: {
    signInActionText() {
      if (this.registering) {
        return "Already have an account?";
      } else {
        return "Don't have an account yet?";
      }
    },
  },
  methods: {
    ...mapMutations(["setUser"]),
    verifyUserExists,
    async onSubmit() {
      
      // TODO: add this back in for prod
      // if (!this.email.includes("@technologypartners.net")) {
      //   this.emailFailure();
      //   return;
      // }

      if (this.registering) {
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.email, this.password)
          .then((response) => {
            response.user
              .updateProfile({
                displayName: this.name,
              })
              .then(async () => {
                // insert a user record into OUR users table, not firebase's auth table
                await setDoc(doc(db, "users", response.user.uid), {
                  name: response.user.displayName,
                  id: response.user.uid,
                  email: response.user.email,
                  isAdmin: false,
                  hireDate: null,
                });
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
      const actionCodeSettings = {
        url: `https://${window.location.host}/emailRedirect`,

        // This must be true.
        handleCodeInApp: true,
      };


      const auth = await getAuth();

      sendSignInLinkToEmail(auth, this.email, actionCodeSettings)
        .then(() => {
          this.verifyUserExists(this.email).then((userExists) => {
          if (userExists) {
            this.loginSuccess();
          } else {
            this.emailFailure('This email is not registered. Please register first.');
          }
          })
        })
        .catch((error) => {
          console.log('error', error)
          this.loginError();
        });
    },
    loginSuccess() {
      localStorage.setItem("emailForSignIn", this.email);
      this.modalMessage =
        "Nice! <br /> <br /> Please check your email for a secure link to finish logging in. <br /> <br /> If you don't see it, be sure to check your spam folder.";
      this.success = true;
      this.showModal = true;
    },
    emailFailure(message = null) {
      this.success = false;
      this.modalMessage = message ? message :
        "Uh Oh... </br> <br /> You must use a '@technologypartners.net' email address. Please try again.";
      this.showModal = true;
    },
    loginError() {
      this.success = false;
      this.modalMessage =
        "There was an error trying to sign you in. Please try again";
      this.showModal = true;
    },
    toggleRegisterView() {
      this.registering = !this.registering;
    },
    beforeClose() {
      this.showModal = false;
    },
  },
  watch: {
    showModal(val) {
      if (val) {
        this.$modal.show("modal");
      } else {
        this.$modal.hide("modal");
      }
    },
  },
};
</script>
<style scoped>
/deep/ .modal-header {
  display: none !important;
}

.modal-text {
  text-align: center;
}
</style>
